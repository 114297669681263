// layout
.map-editor-toolbar {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;

  // separator requires extra space between the two items
  .button-separator-after {
    position: relative;
    margin-right: 0.5em !important;

    // separator adds line between two items
    &:after {
      content: "";
      height: 1.5em;
      width: 2px;
      border-radius: 1px;
      display: inline-block;
      position: absolute;
      left: calc(100% + 0.5em - 1px);
      top: 50%;
      transform: translateY(-50%);
    }

    & + .pure-button {
      margin-left: 0.5em;
    }
  }

  // ensure consistent icon size (see theme below)
  .pure-button > svg {
    display: inline-block;
    vertical-align: middle;
    height: 22px !important;
    width: auto !important;
  }
}

.map-editor-toolbar-actions {
  .map-editor-save-icon{
    margin-left: 1rem;
  }
  

  .pure-button {
    margin-bottom: 0;
  }
  .pure-button + .pure-button {
    margin-left: 1rem;
  }
}

// theme
.map-editor-toolbar {
  background-color: #385980;
  padding: 0.5rem 1rem;
  color: #dbdbe0;

  .pure-button-group .pure-button {
    border-right: 0 none;
  }

  .button-transparent {
    color: inherit;
    &:hover {
      cursor: pointer;
    }

    &:active {
      background-color: $btn-active-color-gray;
    }
  }

  // icon color for disabled buttons
  .button-disabled {
    color: #6a819e;
    pointer-events: none;

    svg {
      & [stroke^="#"] {
        stroke: #6a819e;
      }

      & [fill^="#"] {
        fill: #6a819e;
      }
    }
  }

  .pure-button {
    border-radius: 3px;
    padding: 0.5rem 0.5rem;
  }

  .pure-button-active {
    cursor: pointer;
    background-color: #7bb8ff;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .button-separator-after:after {
    background-color: #6a819e;
  }

  // icon size driven by height
  .pure-button > svg {
    height: 24px;
  }
}

.map-editor-toolbar-actions {
  .pure-button {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .pure-button-primary {
    color: white;
    background-color: #7bb8ff;
  }

  .pure-button-secondary {
    background-color: transparent;
    border: 2px solid #6a819e;
    color: #6a819e;
  }
}

// disable user interactions on first 2 buttons
.map-editor-toolbar .pure-button-group .pure-button {
  &:nth-child(1),
  &:nth-child(2) {
    pointer-events: none;
  }
}


// media query

@media (max-width: 768px){
  .map-editor-toolbar{
    display: flex;
    justify-content: space-between;
    align-items: space-between;
  }
}
