.notification-settings {
  width: 100%;
  margin: 3em 0 0 0;

  .sub-header {
    color: $chambray;
    font-family: $font-family-regular;
    font-size: $font-size-base * 0.8;
    font-weight: normal;
  }

  .setting-control {
    display: flex;
    flex-direction: row;
    border-top: 1px solid rgba(151, 151, 151, 0.25);
    width: 100%;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    justify-content: space-between;
    align-items: flex-start;
  }
  .flex {
    display: flex;
    justify-content: space-between;
  }
  .name {
    color: #284260;
    font-size: 15px;
    font-weight: bold;

    .help-icon {
      margin-left: 0.5em;
    }
  }
  // Custom CheckBox CSS
  .check-holder {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0.3em 0 0 10px;

    .check-label {
      width: 100%;
      margin-left: 2em;
      line-height: 1.3em;
      color: #7d92ac;
      font-weight: bold;
      font-size: 14px;
    }
    .checkmark {
      font-size: 10px;
    }

    .selectable {
      cursor: pointer;
    }

    .active {
      color: $malibu;
      &:hover {
        color: darken($malibu, 10%);
      }
    }

    .inactive {
      color: $gray;
      &:hover {
        color: darken($gray, 10%);
      }
    }
  }

  .disabled {
    pointer-events: none;
    &:hover {
      cursor: not-allowed;
    }
  }

  .settings-icon {
    color: $gray;
    cursor: pointer;
    margin: 0.2em 0 0 10px;
    &:hover {
      color: darken($gray, 10%);
    }
  }

  @include breakpoint("md") {
    width: 48%;
  }
}
