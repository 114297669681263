.inspection-questions {
  margin-bottom: 5rem;

  %input-basis {
    color: $chambray;
    font-size: $font-size-base * 0.9;
  }

  .inspection-question {
    padding: 0.5rem;
  }

  input[type="text"],
  select,
  .react-datepicker__input-container {
    @extend %input-basis;
    height: 44px;
  }

  textarea {
    @extend %input-basis;
  }

  input[type="radio"],
  input[type="checkbox"] {
    width: auto;
    margin: 0 0.5em;
  }

  .react-datepicker__time-container li {
    white-space: nowrap;
  }

  .react-datepicker__input-container,
  textarea {
    width: 100%;
  }

  .input-row {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;

    label:first-of-type {
      flex-basis: 100%;
    }
  }

  .no-padding-top {
    padding-top: 0;
  }
  .no-padding-bottom {
    padding-bottom: 0;
  }

  .radio-label,
  .checkbox-label {
    display: flex;
    align-items: flex-start;
    flex-flow: row wrap;

    &.checkbox-label-single {
      flex-wrap: nowrap;
      align-items: center;
    }

    .description {
      padding-left: 2em;
      width: 100%;
    }

    .control-wrapper {
      display: inline-flex;
      align-items: center;

      span {
        text-align: left;
        color: $chambray;
        font-family: $font-family-regular;
      }
    }
  }

  .description {
    font-size: 0.85rem;
    margin-top: 0;
    margin-bottom: 0;
    color: $chambray;
    font-style: italic;

    ul {
      margin: 0.5em;
      &.alpha-list {
        list-style-type: lower-alpha;
      }
      &.checkbox-list {
        list-style-type: "\2610";

        li {
          padding-left: 0.5rem;
        }
      }
    }
  }

  span.description {
    padding-left: 0.25rem;
    font-size: 0.85rem;
  }

  .inspection-question.descriptive_text > .description {
    font-style: normal;
  }

  textarea {
    resize: none;
  }

  .center .label {
    text-align: left;
  }

  .full-width label {
    width: 100%;
  }

  .label-wrapper {
    display: inline-flex;
    align-items: center;

    label {
      color: $label-color;
    }
  }

  .child-questions-wrapper {
    padding-left: 1.5em;
    margin-bottom: 1em;
  }

  .comment {
    margin: 0 1em;
    font-size: 1.3rem;
    color: $label-color;
  }

  .justify-center {
    justify-content: center;
  }

  .text-left {
    text-align: left;
    span {
      text-align: left;
    }
  }

  // TABLE STYLES
  table.inspection-form-section {
    width: 100%;

    .label {
      width: 50%;
    }
    .label-wide {
      width: 70%;
    }
    .label-small {
      td {
        width: 100%;
      }
      .label {
        width: 33%;
      }
    }
    .child label {
      padding-left: 1em;
      display: flex;
      flex-flow: row nowrap;

      .dash {
        margin: 0 0.5rem 0 0;
      }
    }
    .child label ~ .description {
      padding-left: 2em;
    }
    // head
    th {
      font-family: $font-family-regular;
      padding: 0 0.75em;
      text-align: center;
      &.label {
        text-align: left;
      }
      h3 {
        margin: 0;
      }
      h4 {
        color: $baliHai;
      }
    }
    // body
    td {
      border-top: 1px solid $brookseyBorder;
      border-bottom: 1px solid $brookseyBorder;
      padding: 0.25em;
      text-align: center;
      &.label {
        text-align: left;
      }

      &:first-of-type,
      &:last-of-type {
        border-right: 1px solid $brookseyBorder;
      }
      &.boolean {
        width: 5rem;
        .radio-label {
          width: 100%;
        }
      }
      &.has-comments .label-wrapper {
        justify-content: space-between;
        width: 100%;
      }

      &.comment-only {
        width: 1em;
        border-right: none;
      }

      &.finding\[\] {
        text-align: left;
      }

      .radio-label,
      .checkbox-label {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        span {
          color: $chambray;
          font-family: $font-family-regular;
        }
      }
    }

    textarea,
    input[type="text"],
    .react-datepicker__input-container {
      margin: 0.5em 0;
    }

    .react-datepicker__input-container input[type="text"],
    .tag-select.form-control input {
      margin: 0;
      height: auto;
    }
  }

  .disabled {
    cursor: not-allowed;
  }
}

.comment-modal {
  textarea {
    width: 100%;
    resize: none;
  }
}

// action buttons
.inspection-questions {
  position: relative;

  .action-buttons {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
  }
}

// styles for specific templates

.inspection-questions-cdot {
  // padding to prevent collapsing margins
  & > form {
    padding-top: 1px;
  }
}


// media query
@media (max-width: 768px) {
  .inspection-form-section > div{
    width: 100% !important;
  }

  table.inspection-form-section > tbody > tr > td:nth-child(2) > div{
    padding: 0.25rem 0.125rem;
    display: flex;
    flex-direction: column;
  }
}