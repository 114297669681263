$card-spacer: 20px;
$card-image-cap-height: 280px;

.card {
  background-color: $white;
  border-radius: $border-radius;
  box-shadow: $box-shadow-sm;
  line-height: $card-spacer;
}

.card-img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.card-cap {
  margin-left: -1px;
  margin-right: -1px;
  height: $card-image-cap-height;
}

.card-title {
  color: $chambray;
}

.card-body {
  padding: $card-spacer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  & + & {
    padding-top: 0;
  }
}

.card-content {
  p {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: $card-spacer / 2;
  }
}

.card-title {
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: bold;
}

.card-toolbar {
  flex: 0 0 auto;
  display: flex;
  flex-flow: row nowrap;

  button i {
    font-size: 0.8rem;
  }

  button {
    margin-left: 0.25rem;
  }
}

.card-toolbar-item {
  flex: 0 0 auto;
  font-size: 12px;
  line-height: 1rem;
  text-align: center;
  padding: 3px 5px;

  & + & {
    margin-left: 5px;
  }
}

.card-actions {
  padding-top: 5px;
}

.card-action {
  padding: $card-spacer / 2;
  margin: 0 auto;
  width: 100%;
  font-size: 14px;
}
