.searchContainer{
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 60px;
}

.searchContainer > input{
    max-width: 50%;
    border: 1px solid #c5cfda
}

.addClientMainContainer{
    margin-left: 20px;
    width: 100%;
    position: relative;
}
.addClientContainer{
    position: absolute;
    top: -71px;
    width: 100%;
}

.selectClient{
    display: flex;
    flex-direction: row;
    height: auto;
}
.selectClient > button {
    width: 30%;
    height: 40px;
    border-radius: 0%;
}

#selectInput{
    width: 100%;
    z-index: 1;
    // height: 44px;
}

#selectInput > div:nth-child(2){
    border-radius: 0;
    height: auto;
    border: 1px solid #c5cfda

}

.addClientText{
    color: #7c91ab;
    font-family: "ProximaNovaBold";
    font-weight: normal;
    font-size: 14.4px;
}

.hr{
    background-color: red;
    position: relative;
    width: 100%;
}


