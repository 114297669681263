.weather {
  display: inline;

  p {
    color: $baliHai;
    margin: 0;
    font-size: 0.8rem;
    &.bold {
      font-family: $font-family-bold;
    }
  }

  i {
    color: $baliHai;
  }

  a {
    font-size: 0.6rem;
  }

  .poweredby {
    text-align: right;
  }

  &.weather-denied {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 30rem;
    i {
      font-size: 2.5rem;
    }
  }

  .weather-forecast {
    display: inline-flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex: 1 1 500px;

    .date {
      margin: 0 5px;

      font-family: $font-family-bold;
    }
    .label {
      font-size: 0.6rem;
    }
    i {
      font-size: 1.5rem;
      color: $chambray;
    }
    .weather-day,
    .weather-details-wrapper {
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
    }

    i,
    .weather-day,
    .weather-details {
      margin: 0 5px;
    }

    .weather-day:last-of-type {
      display: none;
    }

    .high {
      color: $chambray;
    }
  }

  .loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .animated-line {
      stroke: $rhino;
      fill: $rhino;
    }
  }

  // responsive cleanup
  @media screen and (min-width: $breakpoint-md) {
    .weather-forecast,
    &.weather-denied {
      justify-content: flex-end;
    }
  }
  @media screen and (min-width: $breakpoint-sm) {
    .weather-forecast .weather-day:last-of-type {
      display: inline-flex;
    }
  }
}
