// layout
.map-editor {
  position: relative;
  background: $white;

  // ensure the map editor takes up the entire vertical space
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  .map-editor-top {
    flex: 0 0 auto;
  }

  .map-editor-main {
    flex: 1 1 100px;
    overflow: auto;
  }

  // important! ensure the editor components fill the screen
  .map-editor-layers,
  .map-editor-map,
  .positionable-navigation {
    height: 100%;
  }

  .map-editor-hamburger-icon{
    display: none;
  }

  /* theme styles */
  color: #dbdbe0;

  // white outline on user interaction on transparent buttons
  .button-transparent {
    &:hover,
    &:focus {
      box-shadow: 0 0 0 1px rgba(250, 250, 255, 0.15) inset,
        0 0 6px rgba(250, 250, 255, 0.2) inset;
    }

    &:active,
    &:focus {
      background-image: linear-gradient(
        transparent,
        rgba(250, 250, 255, 0.05) 40%,
        rgba(250, 250, 255, 0.1)
      );
    }

    // selected state
    &.pure-button-active {
      color: white;
    }
  }

  .error {
    color: $bitterSweet;
    text-align: center;
  }
}

.map-editor-sidebar {
  position: relative;
  overflow: auto;

  .positionable-navigation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.map-editor-layers {
  background-color: lightgray;
}

.map-loader {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  padding-top: 1rem;

  p {
    margin: 0;
  }
}


// media query

@media (max-width: $breakpoint-md){
  .map-editor-sidebar{
    box-shadow: 2px 4px 12px gray;
    color: red;
    position: absolute;
    height: 12.5rem;
    width: 20rem;
    top: 10.96rem;
    left: 0.7rem;
    z-index: 1;
  }

  .map-editor-main > div:last-child{
    z-index: 0;
  }

  .map-editor-map{
    width: 100%;
  }

  .map-editor-hamburger-icon{
    display: block !important;
    position: absolute;
    top: 8.4rem;
    left: 0.7rem;
    height: 2.55rem;
    width: 2.8rem;
    padding: 0.5rem;
    z-Index: 1;
    background: rgba(33,58,87,0.8);
    display: flex;
    place-Items: center;
  }

  .map-editor-hamburger-svg{
    height: 100%;
    width: 100% !important;
  }

  .map-editor-layers > div > div:last-child > div:first-child {
    top: 4rem;
  }
}