$v-space-line: 1rem;

.form-group {
  margin-bottom: $v-space-line;
  position: relative;

  & > label {
    display: block;
    margin-bottom: 0.25rem;
    color: $label-color;
    font-family: $font-family-bold;
    font-weight: normal;
  }
}

@mixin controlLayout() {
  display: block;
  width: 100%;
  margin: 0 0 0.25rem;
  color: $chambray;
  font-family: $font-family-regular;
  line-height: $line-height-base * 1.1;

  & & {
    margin-bottom: 0;
  }
}

@mixin controlThemeFocused() {
  outline: 0;
  outline: none;
  box-shadow: $box-shadow-color-gray;
}

@mixin controlTheme() {
  padding: 0.5rem 0.75rem;
  border: $control-theme-border-color;
  border-radius: 0;
}

.form-control {
  @include controlLayout();

  &:not(.form-control--no-theme) {
    @include controlTheme();
  }

  &:focus {
    @include controlThemeFocused();
    background-color: $btn-focus-color-gray;
  }
}

// specific control types
.form-group--checkbox {
  label {
    display: flex;
    flex-flow: row-reverse;
    justify-content: flex-end;
    align-items: center;

    color: $chambray;
    font-family: $font-family-regular;

    &:hover {
      color: darken($chambray, 12%);
    }
  }

  .form-control {
    width: auto;
    flex-grow: 0;
    margin: 0 0.5rem 0 0;
  }
}

.form-group--checkboxGroup {
  .form-text {
    text-align: right;
  }

  .checkbox-group-options {
    padding-left: 2em;
  }
}
