// layout
.map-editor-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  gap: 1.5rem;

  .map-editor-header-left{
    display: flex;
    flex-direction: column;
  }

  .map-editor-header-right{
    display: flex;
  }

  .map-editor-header-info{
    display: flex;
    flex-direction: column;
  }

  .map-updated-at,
  .map-close {
    margin: 0;
  }

  .map-updated-at {
    margin-left: auto;
    margin-right: 0;

    & ~ * {
      margin-left: 1rem;
      margin-right: 0;
    }
  }
}

// theme

.map-editor-header {
  background-color: rgb(33, 58, 87);
  color: inherit;
  padding: 1rem 0.5rem 0.5rem;

  h1,
  h2 {
    margin: 0;
    line-height: 1.5rem;
  }

  h1 {
    font-size: 1.5rem;
    font-family: "ProximaNovaThin";
  }

  h2 {
    font-size: 1rem;
    }

  .map-updated-at {
    font-size: 0.75rem;
    font-family: "ProximaNovaRegular";
  }

  .map-select-inspection {
    font-family: "ProximaNovaRegular";

    background-color: transparent;
    border: 0 none;
    margin-left: 1.5rem;

    option {
      font-weight: normal;
    }
  }

  h1,
  h2,
  h3,
  .map-updated-at,
  .map-close {
    color: inherit;
  }
}


// media Query

@media (max-width: 768px){
  .map-editor-header-left{
    min-width: 0;
  }

  .map-editor-header-left h1,h2{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (max-width: 428px){
  .map-editor-header-left > h1{
    font-size: 1rem;
  }

  .map-editor-header-left > h2{
    font-size: 0.8rem;
    font-weight: 200;
  }
}