.layout--sidebar {
  max-width: $layout-width;

  .sidebar {
    color: $white;
    background-color: $body-bg;
    min-height: 100vh;
    width: 15em;
    display: inline-block;
    vertical-align: top;

    .logo {
      margin: 1em;
      height: auto;
      width: 80%;
      max-width: 8em;
    }

    .nav {
      display: none;
    }
  }

  .content {
    padding: 0 73px;
    background-color: $white;
    min-height: 100vh;
    display: inline-block;
    width: $content-width;
    vertical-align: top;
  }

  @include breakpoint('up-to-desktop') {
    .content {
      width: 100%;
      padding: 0;
    }

    .sidebar {
      display: none;
    }
  }
}