// Add a breakpoint-specific rules for a query
@mixin breakpoint($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin backgroundGrid($boxSize: 50px, $boxColor: #f2f2f2) {
  $c: $boxColor;
  $s: $boxSize;
  background-size: $s $s;
  background-position: 0 0, ($s / 2) ($s / 2);
  background-image: -webkit-linear-gradient(
      45deg,
      #{$c} 25%,
      transparent 25%,
      transparent 75%,
      #{$c} 75%,
      #{$c}
    ),
    -webkit-linear-gradient(45deg, #{$c} 25%, transparent 25%, transparent 75%, #{$c}
          75%, #{$c});
  background-image: -moz-linear-gradient(
      45deg,
      #{$c} 25%,
      transparent 25%,
      transparent 75%,
      #{$c} 75%,
      #{$c}
    ),
    -moz-linear-gradient(45deg, #{$c} 25%, transparent 25%, transparent 75%, #{$c}
          75%, #{$c});
  background-image: linear-gradient(
      45deg,
      #{$c} 25%,
      transparent 25%,
      transparent 75%,
      #{$c} 75%,
      #{$c}
    ),
    linear-gradient(
      45deg,
      #{$c} 25%,
      transparent 25%,
      transparent 75%,
      #{$c} 75%,
      #{$c}
    );
}

@mixin background-opacity($color, $opacity) {
  background: $color; /* The Fallback */
  background: rgba($color, $opacity);
}
