.react-tabs__tab-list {
  border-bottom: $border;
  margin-bottom: 1rem;
}

.react-tabs__tab {
  color: #ccc;
  font-weight: bold;
  border: none;
  border-bottom: $border-radius solid $mercury;
  font-size: $font-size-base * 0.8;
  text-decoration: none;
}

.react-tabs__tab--selected {
  color: $rhino;
  font-weight: bold;
  border: none;
  border-bottom: $border-radius solid $rhino;
}

.react-tabs__tab:focus {
  box-shadow: none;
  border-color: inherit;
  outline: none;

  &::after {
    display: none;
  }

  &:active {
    background-color: $navtab-active-color-gray;
    border-radius: 10%;
  }

  &.react-tabs__tab--selected {
    border-bottom: $border-radius solid $rhino;
  }
}

@media screen and (max-width: $breakpoint-md) {
  .react-tabs__tab-list {
    height: 2.5rem;
    overflow: auto;
    white-space: nowrap;
  }
}